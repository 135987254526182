import { faHeadset, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PureComponent } from 'react';
import Chatbot from 'react-chatbot-kit';

import { isApp } from 'Util/Mobile/isMobile';

import 'react-chatbot-kit/build/main.css';
import './YBChatbot.style';

/** @namespace Elganso/Component/YBChatbot/Component */
export class YBChatbot extends PureComponent {
    render() {
        const {
            config, onClickButtonChat, openedChatbot, showChatbot,
            messageParser, actionProvider
        } = this.props;

        if (showChatbot) {
            return (
                <div block="YBChatbot">
                    <span
                      block="YBChatbot"
                      elem="CloseButton"
                      onClick={ onClickButtonChat }
                      onKeyDown={ onClickButtonChat }
                      className={ openedChatbot ? 'chatbotOpen' : 'chatbotClose' }
                    >
                        <FontAwesomeIcon
                          className="YBChatbot-ImageButton"
                          icon={ faXmark }
                        />
                    </span>
                    <span
                      block="YBChatbot"
                      elem="Button"
                      onClick={ onClickButtonChat }
                      onKeyDown={ onClickButtonChat }
                      className={ isApp() ? 'chatbotApp' : '' }
                    >
                        <FontAwesomeIcon
                          className="YBChatbot-ImageButton"
                          icon={ faHeadset }
                        />
                    </span>
                    <div block="YBChatbot" elem="ChatbotContainer">
                        <div
                          block="YBChatbot"
                          elem="Chat"
                          className={ openedChatbot ? 'chatbotOpen' : 'chatbotClose' }
                        >
                            { /* https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/ */ }
                            <Chatbot
                              config={ config }
                              messageParser={ messageParser }
                              actionProvider={ actionProvider }
                              headerText={ config.botName }
                              placeholderText={ __('Write here...') }
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default YBChatbot;
