import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldForm from 'Component/FieldForm';
import FieldSelect from 'Component/FieldSelect';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { appendWithStoreCode } from 'Util/Url';

import { OutOfStockFormulario as outOfStockFormulario } from './OutOfStockForm.form';

import './OutOfStockForm.style';

/** @namespace Elganso/Component/OutOfStockForm/Component */
export class OutOfStockForm extends FieldForm {
    static propTypes = {
        onAcceptTerm: PropTypes.func.isRequired,
        submitSubscription: PropTypes.func.isRequired
    };

    onFormSuccess = this.onFormSuccess.bind(this);

    get fieldMap() {
        const {
            onAcceptTerm
        } = this.props;

        return outOfStockFormulario(this.props, { onAcceptTerm });
    }

    async onFormSuccess(form, fields) {
        const {
            submitSubscription
        } = this.props;

        submitSubscription(form, fields);
    }

    setRefFunction(_a, _v) {
        return Math.random();
    }

    renderDropdown(option) {
        const { _getSelectOptions, onConfigurableSelected } = this.props;
        const { attribute_code, attribute_label } = option;
        const options = _getSelectOptions(option).filter((opt) => opt.isAvailable === true).sort((a, b) => a.label > b.label);
        if (options) {
            options[0].isPlaceholder = true;
        }

        // provincias.map(i => options.push({ label: i, value: i, key: i, 'id': this.getNombreProvincia(i), 'isPlaceholder': false }));
        return (
            <div block="OutOfStockForm" elem="productOptions">
                <span block="OutOfStockForm" elem="sizeTitle">
                    { attribute_label.toUpperCase() }
                </span>
                <div block="OutOfStockForm" elem="sizeField">
                    <FieldSelect
                      addRequiredTag
                      isDisabled={ false }
                      key={ attribute_code }
                      attr={ { selectPlaceholder: ' ' } }
                      events={ { onChange: onConfigurableSelected } }
                      options={ options }
                      setRef={ this.setRefFunction }
                    />
                </div>
            </div>
        );
    }

    renderProductConfigurableAttributes() {
        const {
            product,
            productoSeleccionado
        } = this.props;

        if (productoSeleccionado !== undefined || product.type_id !== 'configurable') {
            return null;
        }
        const { product: { configurable_options } } = this.props;
        return Object.values(configurable_options).map((option) => {
            if (option.attribute_code !== 'size') {
                return null;
            }
            const {
                attribute_id
            } = option;

            // const selectedOption = parameters[attribute_code];
            // const selectedOptionLabel = selectedOption ? attribute_options[selectedOption]?.label : '';

            return (
                <div key={ attribute_id }>
                    { this.renderDropdown(option) }
                </div>
            );
        });
    }

    renderActions() {
        const {
            isFormLoading, termsAreEnabled, onAcceptTerm, respuestaSuscripcion
        } = this.props;

        return (
            <>
                { this.renderProductConfigurableAttributes() }
                <div className="accept-term-continer">
                    <label
                      block="OutOfStockForm"
                      elem="accept_term_label"
                      htmlFor="accept_term"
                    >
                        <Field
                          type={ FIELD_TYPE.checkbox }
                          attr={ {
                              id: 'accept_term',
                              name: 'accept_term',
                              value: 'accept_term'
                          } }
                          events={ { onChange: onAcceptTerm } }
                        />
                        <span className="privacy-policy-text">
                            { __('I have read and accept the ') }
                            <Link
                              to={ appendWithStoreCode('/politica-de-privacidad') }
                              rel="noopener noreferrer"
                              target="_blank"
                              className="privacy-policy-link"
                            >
                                { __('privacy policy') }
                            </Link>
                        </span>
                    </label>
                </div>

                <ul className="lista-condiciones-lopd">
                    <li>{ __('El responsable de los datos que nos dejes somos nosotros, con nuestro nombre social aburrido, ACTURUS CAPITAL, S.L., con C.I.F. B83233346, domiciliado a estos efectos en la calle C/ Impresores 14, Polígono Industrial Prado Del Espino, 28660 Boadilla del Monte, Madrid.') }</li>
                    <li>{ __('La finalidad de los datos que nos dejes será para poder comunicarte vía email (no tenemos gansos mensajeros) nuestras novedades u ofertas que encontremos interesantes para ti.') }</li>
                    <li>{ __('Al aceptar nuestra política de privacidad, aceptas que tus datos los tratemos conforme al Reglamento (UE) 2016/679, de tratamiento de datos de carácter personal. No te preocupes, lo único que queremos es enviarte de vez en cuando nuestras novedades.') }</li>
                    <li>{ __('Conservaremos tus datos mientras consideremos que sea necesario para cumplir con nuestro servicio o en su defecto, por el periodo legalmente establecido.') }</li>
                    <li>
                        { __('Para Acceder, rectificar y suprimir los datos, así como el ejercicio de otros derechos o reclamaciones, debes ponerte en contacto con nuestro DPO, David Díaz, en ') }
                        { /* eslint-disable-next-line react/forbid-elements */ }
                        <a href="mailto:privacidad@elganso.com" target="_blank" rel="noreferrer" className="privacy-policy-link">{ __('privacidad@elganso.com') }</a>
                    </li>
                    <li>
                        { __('Para más información sobre tus derechos y sobre la normativa de protección de datos aplicable puede consultar nuestra Política de Privacidad ') }
                        <Link
                          to={ appendWithStoreCode('/politica-de-privacidad') }
                          rel="noopener noreferrer"
                          target="_blank"
                          className="privacy-policy-link"
                        >
                            { __('aquí') }
                        </Link>
                    </li>
                </ul>
                { isFormLoading && <Loader isLoading={ isFormLoading } /> }
                <button className={ !termsAreEnabled ? 'privPolDisabled Button' : 'Button' } type="submit" block="OutOfStockForm" elem="Suscribe-Button">
                    { __('Suscribirse') }
                </button>
                <div
                  className="container-result-suscripcion"
                >
                    <span
                      className={ respuestaSuscripcion?.addSubscription.result ? 'result-suscripcion-ok' : 'result-suscripcion-false' }
                    >
                        { respuestaSuscripcion?.addSubscription.message }
                    </span>
                </div>
            </>
        );
    }

    getFormProps() {
        return {
            onSubmit: this.onFormSuccess
        };
    }
}

export default OutOfStockForm;
