import {
    faCalendarXmark, faCloudXmark, faInputNumeric, faInputText, faMessageExclamation, faMoneyBillWave, faOctagonExclamation, faShieldSlash
} from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';
import { noopFn } from 'Util/Common';

/** @namespace Elganso/Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Elganso/Component/Popup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Elganso/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    static defaultProps = {
        onVisible: noopFn,
        onClose: noopFn,
        onHide: noopFn,
        mix: {},
        contentMix: {},
        children: [],
        isStatic: false,
        renderTitle: true
    };

    containerProps() {
        const {
            activeOverlay,
            areOtherOverlaysOpen,
            changeHeaderState,
            clickOutside,
            children,
            id,
            isMobile,
            isStatic,
            mix,
            contentMix,
            onClose,
            onHide,
            onVisible,
            shouldPopupClose,
            hideActiveOverlay,
            resetHideActivePopup,
            goToPreviousNavigationState,
            renderTitle,
            idBuilder
        } = this.props;

        return {
            activeOverlay,
            areOtherOverlaysOpen,
            changeHeaderState,
            clickOutside,
            children,
            id,
            isMobile,
            isStatic,
            mix,
            contentMix,
            shouldPopupClose,
            onClose,
            onHide,
            onVisible,
            hideActiveOverlay,
            resetHideActivePopup,
            goToPreviousNavigationState,
            renderTitle,
            title: this._getPopupTitle(),
            product: this._getProductOutOfStock(),
            productClicked: this._getProductClickedOutOfStock(),
            isOutOfStock: this._getIsOutOfStock(),
            params: this._getParameters(),
            idBuilder,
            stripeError: this.getStripeError()
        };
    }

    _getPopupTitle() {
        const { payload, activeOverlay } = this.props;
        const { title } = payload[activeOverlay] || {};

        return title;
    }

    // Tengo que capturar los parametros del payload
    _getProductOutOfStock() {
        const { payload, activeOverlay } = this.props;
        const { product } = payload[activeOverlay] || {};

        return product;
    }

    _getProductClickedOutOfStock() {
        const { payload, activeOverlay } = this.props;
        const { productClicked } = payload[activeOverlay] || {};

        return productClicked;
    }

    _getIsOutOfStock() {
        const { payload, activeOverlay } = this.props;
        const { isOutOfStock } = payload[activeOverlay] || {};

        return isOutOfStock;
    }

    _getParameters() {
        const { payload, activeOverlay } = this.props;
        // console.log(payload);
        // console.log(payload[activeOverlay]);
        const { params } = payload[activeOverlay] || {};

        return params;
    }

    getStripeError() {
        // https://docs.stripe.com/error-codes
        const { payload, activeOverlay } = this.props;
        const { stripeError = null, stripeCode = null } = payload[activeOverlay] || {};

        if (!stripeError && !stripeCode) {
            return null;
        }

        let errorMessage = '';
        let icon = faMessageExclamation;

        switch (stripeCode) {
        case 'payment_intent_incompatible_payment_method':
            errorMessage = __('Alguno de los parametros configurados en el método de pago seleccionado no son válidos o tienen formato no válido.');
            icon = faOctagonExclamation;
            break;
        case 'financial_connections_account_inactive':
        case 'financial_connections_no_successful_transaction_refresh':
        case 'forwarding_api_upstream_connection_error':
        case 'forwarding_api_upstream_connection_timeout':
            errorMessage = __('En estos momentos no podemos procesar tu pago. Por favor, inténtalo de nuevo más tarde.');
            icon = faCloudXmark;
            break;
        case 'incorrect_address':
            errorMessage = __('La dirección de su tarjeta/método de pago es incorrecta.');
            icon = faInputText;
            break;
        case 'email_invalid':
            errorMessage = __('El email de su tarjeta/método de pago es incorrecto.');
            icon = faInputText;
            break;
        case 'incorrect_cvc':
        case 'invalid_cvc':
            errorMessage = __('El código de seguridad de su tarjeta es incorrecto.');
            icon = faInputNumeric;
            break;
        case 'incorrect_number':
            errorMessage = __('El número de su tarjeta es incorrecto.');
            icon = faInputNumeric;
            break;
        case 'postal_code_invalid':
        case 'incorrect_zip':
            errorMessage = __('El código postal de su tarjeta/método de pago es incorrecto.');
            icon = faInputNumeric;
            break;
        case 'expired_card':
            errorMessage = __('La tarjeta ha caducado.');
            icon = faCalendarXmark;
            break;
        case 'insufficient_funds':
            errorMessage = __('Fondos insuficientes, revise su método de pago y vuelva a intentarlo de nuevo.');
            icon = faMoneyBillWave;
            break;
        case 'payouts_limit_exceeded':
            errorMessage = __('Ha superado el límite de pagos permitidos.');
            icon = faShieldSlash;
            break;
        case 'processing_error':
            errorMessage = __('Error en el procesamiento de la transacción.');
            icon = faMessageExclamation;
            break;
        case 'card_declined':
            errorMessage = __('Tu tarjeta ha sido rechazada.');
            icon = faMessageExclamation;
            break;
        default:
            errorMessage = stripeError;
            break;
        }

        return {
            icon,
            message: errorMessage
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
