import { createRef, PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import ProductListQuery from 'Query/ProductList.query';
import history from 'Util/History';
import { formatPrice } from 'Util/Price';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/GetSearchForm/Component */
export class GetSearchForm extends PureComponent {
    state = {
        searchCriteria: '',
        isLoading: false
    };

    searchBarRef = createRef();

    onSearchBarChange = this.onSearchBarChange.bind(this);

    onSearchEnterPress = this.onSearchEnterPress.bind(this);

    clearSearch = this.clearSearch.bind(this);

    makeSearchRequest = this.makeSearchRequest.bind(this);

    componentDidMount() {
        // Hacemos focus en el input al cargar
        this.searchBarRef.current.focus();
    }

    componentDidUpdate(_, prevState) {
        const { searchCriteria: prevSearchCriteria } = prevState;
        const { searchCriteria } = this.state;

        // Buscamos cuando estamos escribiendo
        if (searchCriteria !== '' && searchCriteria !== prevSearchCriteria) {
            this.setState({ isLoading: true });
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.timeout = null;
                this.makeSearchRequest();
            }, 500);
        }
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    onSearchEnterPress({ key, target: { value: searchCriteria } }) {
        if (searchCriteria !== '') {
            const trimmedSearch = searchCriteria.trim();

            // Si tenemos busqueda y le damos a enter redirigimos a la pag de búsqueda
            if (key === 'Enter' && trimmedSearch !== '') {
                const url = `search/${encodeURIComponent(searchCriteria.trim().replace(/%/g, '%25'))}`;

                history.push(appendWithStoreCode(url));
                this.searchBarRef.current.blur();
                this.clearSearch();
            }
        }
    }

    clearSearch() {
        this.setState({ searchCriteria: '' });
        this.searchBarRef.current.focus();
    }

    makeSearchRequest() {
        const { searchCriteria } = this.state;

        if (searchCriteria) {
            const search = encodeURIComponent(searchCriteria.trim().replace(/%/g, '%25'));

            const options = {
                args: {
                    search,
                    pageSize: 4,
                    currentPage: 1
                },
                notRequireInfo: true,
                noVariants: true
            };

            executeGet(prepareQuery(ProductListQuery.getQuery(options))).then(
                /** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/GetSearchForm/Component/GetSearchForm/makeSearchRequest/executeGet/then */
                (result) => {
                    if (result?.products?.items) {
                        this.setState({ products: result.products.items });
                    }

                    this.setState({ isLoading: false });
                }
            );
        }
    }

    renderSearchItem(product = {}, i) {
        const {
            image: { url: imageUrl } = {},
            name,
            price_range: {
                maximum_price: {
                    final_price: {
                        value,
                        currency
                    } = {}
                } = {}
            } = {},
            url = ''
        } = product;

        let price = '';

        if (value) {
            price = formatPrice(value, currency);
        }

        return (
            <Link
              key={ i }
              to={ url }
              className="chatbot-search-results-item"
            >
                { name && !imageUrl ? (
                    <div className="chatbot-search-results-item-image" />
                ) : (
                    <Image
                      key={ i }
                      className="chatbot-search-results-item-image"
                      src={ imageUrl }
                      alt={ __('Product %s thumbnail.', name) }
                      isPlaceholder={ !name }
                    />
                ) }
                <div className="chatbot-search-results-item-info">
                    <h4 block="SearchItem" elem="Title" className="name" mods={ { isLoaded: !!name } }>
                        <TextPlaceholder content={ name } length="long" />
                    </h4>
                    <div block="SearchItem" elem="Price" className="price" mods={ { isLoaded: !!price } }>
                        <TextPlaceholder content={ price } length="short" />
                    </div>
                </div>
            </Link>
        );
    }

    renderNoResults() {
        return <p className="chatbot-search-noresults">{ __('No results found!') }</p>;
    }

    renderSearchResults() {
        const { searchCriteria, products = [], isLoading } = this.state;

        // Si esta vacio no cargamos nada
        if (searchCriteria === '') {
            return null;
        }

        // Si no ha encontrado productos y ya ha terminado la llamada mostramos mensaje sin resultados
        if (!products.length && !isLoading && !this.timeout) {
            return this.renderNoResults();
        }

        // Cargamos los productos, si no tenemos creamos un array vacio con 4 posiciones para ver los placeholders de carga
        const resultsToRender = (isLoading || this.timeout) ? Array(4).fill({}) : products;

        return (
            <ul className="chatbot-search-results">
                { resultsToRender.map((item, i) => this.renderSearchItem(item, i)) }
            </ul>
        );
    }

    renderSearchField() {
        const { searchCriteria } = this.state;

        return (
            <div className="chatbot-search-container">
                <input
                  id="chatbot-search-field"
                  ref={ this.searchBarRef }
                  onChange={ this.onSearchBarChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  placeholder={ __('Search products') }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                { searchCriteria ? (
                    <div
                      id="chatbot-search-close"
                      role="button"
                      tabIndex="0"
                      onClick={ this.clearSearch }
                      onKeyDown={ this.clearSearch }
                      aria-label={ __('Close') }
                    >
                        <CloseIcon />
                    </div>
                ) : null }
            </div>
        );
    }

    renderSeeAllItems() {
        const { searchCriteria, products = [] } = this.state;

        if (searchCriteria !== '' && products.length) {
            const url = `search/${searchCriteria}`;

            return (
                <li className="chatbot-search-button">
                    <Link to={ appendWithStoreCode(url) } className="Button">
                        { __('Ver más resultados') }
                    </Link>
                </li>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="search-form">
                { this.renderSearchField() }
                { this.renderSearchResults() }
                { this.renderSeeAllItems() }
            </div>
        );
    }
}

export default GetSearchForm;
