import { PureComponent } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Link from 'Component/Link';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/InitButtons/Component */
export class InitButtons extends PureComponent {
    render() {
        const { actionProvider } = this.props;

        const options = [
            {
                id: 'search',
                name: __('Find Your Products'),
                action: actionProvider.formSearch,
                type: 'button'
            },
            // Lo dejo comentado ya que nos vale para hacer pruebas
            // {
            //     id: 'pedido',
            //     name: 'pedido',
            //     action: actionProvider.formGetOrder,
            //     type: 'button'
            // },
            {
                id: 'rrss',
                name: __('Social Networks'),
                action: actionProvider.showSocialMedia,
                type: 'button'
            },
            {
                id: 'contact',
                name: __('Contact'),
                url: '/contact',
                type: 'link'
            },
            {
                id: 'tiendas',
                name: __('Tiendas'),
                url: '/storespage',
                type: 'link'
            },
            {
                id: 'tiendas-wholesale',
                name: __('Wholesale Stores'),
                url: '/tiendas-wholesale',
                type: 'link'
            },
            {
                id: 'politica-de-privacidad',
                name: __('Privacy Policy'),
                url: '/politica-de-privacidad',
                type: 'link'
            },
            {
                id: 'politica-de-cookies',
                name: __('Cookie policy'),
                url: '/politica-de-cookies',
                type: 'link'
            },
            {
                id: 'aviso-legal',
                name: __('Legal Advice'),
                url: '/aviso-legal',
                type: 'link'
            },
            {
                id: 'terminos-y-condiciones',
                name: __('Terms and Conditions'),
                url: '/terminos-y-condiciones',
                type: 'link'
            }
        ];

        return (
            <div className="options">
                <Swiper
                  navigation={ false }
                  modules={ [Navigation] }
                  spaceBetween={ 0 }
                  slidesPerView="auto"
                  centerInsufficientSlides
                  initialSlide={ 0 }
                >
                    { options.map((opt) => {
                        if (opt.type === 'link') {
                            return (
                                <SwiperSlide key={ opt.id }>
                                    <Link
                                      to={ opt.url }
                                      rel="noreferrer"
                                    >
                                        { opt.name }
                                    </Link>
                                </SwiperSlide>
                            );
                        }
                        if (opt.type === 'button') {
                            return (
                                <SwiperSlide key={ opt.id }>
                                    { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
                                    <button
                                      onClick={ opt.action }
                                    >
                                        { opt.name }
                                    </button>
                                </SwiperSlide>
                            );
                        }

                        return null;
                    }) }
                </Swiper>
            </div>
        );
    }
}

export default InitButtons;
