import {
    faAddressBook, faLocationDot, faMinus, faPhone, faPlus
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PureComponent } from 'react';

import './ShowDisponibilidadTienda.style';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/ShowDisponibilidadTienda/Component */
export class ShowDisponibilidadTienda extends PureComponent {
    state = {
        itemOpened: '',
        showMore: false
    };

    clicShowInfoShop = this.clicShowInfoShop.bind(this);

    clicShowInfoShop(codAlmacen) {
        const { itemOpened } = this.state;

        return () => {
            this.setState({ itemOpened: itemOpened === codAlmacen ? '' : codAlmacen });
        };
    }

    renderItemTienda(tienda) {
        const { itemOpened } = this.state;
        const { tallas } = tienda;
        let renderTallas = null;

        if (tallas.length > 1 || (tallas.lenght === 1 && tallas[0] !== 'TU')) {
            renderTallas = '';

            tallas.forEach((talla, id) => {
                if (tallas.length === 1 || id === tallas.length - 1) {
                    renderTallas += `${ talla }`;
                } else {
                    renderTallas += `${ talla }, `;
                }
            });
        }

        return (
            <div className="tienda-container">
                <FontAwesomeIcon className={ itemOpened === tienda.codAlmacen ? 'iconShowInfo closed' : 'iconShowInfo opened' } icon={ faPlus } onClick={ this.clicShowInfoShop(tienda.codAlmacen) } />
                <FontAwesomeIcon className={ itemOpened === tienda.codAlmacen ? 'iconShowInfo opened' : 'iconShowInfo closed' } icon={ faMinus } onClick={ this.clicShowInfoShop(tienda.codAlmacen) } />
                <div>
                    <div
                      className="tienda-subcontainer"
                      onClick={ this.clicShowInfoShop(tienda.codAlmacen) }
                      onKeyDown={ this.clicShowInfoShop(tienda.codAlmacen) }
                    >
                        { `${ tienda.nombre } - ${ tienda.ciudad } (${ tienda.provincia })` }
                    </div>
                    <div className={ itemOpened === tienda.codAlmacen ? 'opened' : 'closed' }>
                        <div className="addressLine">
                            <FontAwesomeIcon className="addressIcon" icon={ faAddressBook } />
                            <div>
                                <p>
                                    { tienda.direccion } <br />
                                    { `${ tienda.codpostal } - ${ tienda.ciudad } (${ tienda.provincia })` }
                                </p>
                            </div>
                        </div>
                        { tienda.telefono
                            ? (
                            <p>
                                { /* eslint-disable-next-line react/forbid-elements */ }
                                <a href={ `tel:${ tienda.telefono }` } className="phoneNumber">
                                    <FontAwesomeIcon className="phoneIcon" icon={ faPhone } />{ tienda.telefono }
                                </a>
                            </p>
                            ) : '' }
                        <p>
                            <span
                              className="toMapButton"
                              onClick={ () => window.open(`https://maps.google.com?q=${tienda.latitud},${tienda.longitud}`) }
                              onKeyDown={ () => window.open(`https://maps.google.com?q=${tienda.latitud},${tienda.longitud}`) }
                            >
                                <FontAwesomeIcon className="locationIcon" icon={ faLocationDot } />{ __('See on map') }
                            </span>
                        </p>
                    </div>
                    { renderTallas !== null
                        ? (
                        <p className="tallas">
                            <strong>{ __('Available sizes') }: </strong>
                            { renderTallas }
                        </p>
                        ) : '' }
                </div>
            </div>
        );
    }

    render() {
        const { payload: { storesData } } = this.props;
        const { showMore } = this.state;

        const renderTallas = storesData.map((tienda, i) => (
            <li
              key={ `li-${ tienda.codAlmacen }` }
              className={ i > 2 && !showMore ? 'tienda closed' : 'tienda' }
            >
                { this.renderItemTienda(tienda) }
            </li>
        ));

        return (
            <div className="disponibilidad-tienda-container">
                <ul className="localizaciones">
                    { renderTallas }
                </ul>
                { storesData.length > 2
                    ? (
                        <div
                          className={ showMore ? 'mostrar-mas closed' : 'mostrar-mas' }
                          onClick={ () => this.setState({ showMore: true }) }
                          onKeyDown={ () => this.setState({ showMore: true }) }
                        >
                            { __('See more') }
                        </div>
                    ) : '' }
            </div>
        );
    }
}

export default ShowDisponibilidadTienda;
