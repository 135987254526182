import { Field } from 'Util/Query';

/**
  * YBPopupBuilder Query
  * @class YBPopupBuilder
  * @namespace Elganso/Query/YBPopupBuilder/Query */
export class YBPopupBuilder {
    getPopupBuilderQuery(options) {
        return new Field('getPopUpList')
            .addArgument('ids', 'String!', options)
            .addFieldList(this._getPopupBuilderFields());
    }

    _getPopupBuilderFields() {
        return [
            'id',
            'html',
            'style',
            'configuration',
            'widget'
        ];
    }

    createPopupReport(id, type) {
        return new Field('createPopupReport')
            .addArgument('id', 'String!', id)
            .addArgument('type', 'String!', type);
    }
}

export default new YBPopupBuilder();
