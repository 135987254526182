import { PureComponent } from 'react';

import Link from 'Component/Link';
import { isSignedIn } from 'Util/Auth';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/ShowRmaInfo/Component */
export class ShowRmaInfo extends PureComponent {
    formatDate(fecha) {
        let dateArray = fecha.split('-');
        let text = '';

        dateArray = dateArray.reverse();
        text = dateArray.join('/');

        return text;
    }

    formatStatus(status) {
        let fraseStatus = status;

        switch (status) {
        case 'complete':
            fraseStatus = __('Complete');
            break;
        case 'en_camino':
            fraseStatus = __('Enviado');
            break;
        case 'processing':
        case 'Processing':
            fraseStatus = __('Processing');
            break;
        case 'pending':
        case 'Pending':
            fraseStatus = __('Pending');
            break;
        case 'closed':
        case 'Closed':
            fraseStatus = __('Closed');
            break;
        case 'canceled':
        case 'Canceled':
            fraseStatus = __('Canceled');
            break;
        case 'Solved':
            fraseStatus = __('Solved');
            break;
        case 'Declined':
            fraseStatus = __('Declined');
            break;
        case 'Delivered':
            fraseStatus = __('Delivered');
            break;
        case 'Not Delivered':
            fraseStatus = __('Not Delivered');
            break;
        case 'Authorized':
            fraseStatus = __('Authorized');
            break;
        case 'Not Applicable':
            fraseStatus = __('Not Applicable');
            break;
        case 'Refund':
            fraseStatus = __('Refund');
            break;
        case 'Reemplazar':
            fraseStatus = __('Reemplazar');
            break;
        case 'Cancel Items':
            fraseStatus = __('Cancel Items');
            break;
        case 'Pending payment':
            fraseStatus = __('Pending payment');
            break;
        default:
            fraseStatus = status;
        }

        return fraseStatus;
    }

    render() {
        const { payload: { rmaData } } = this.props;
        const date = this.formatDate(rmaData.created_at);
        const rma_status = this.formatStatus(rmaData.rma_status);
        const order_status = this.formatStatus(rmaData.rma_order_status);
        const rma_resolution = this.formatStatus(rmaData.rma_resolucion);

        return (
            <div className="rma-info-container" key={ rmaData.rma_id }>
                <div className="rma-info-item rma-id">
                    <div className="label">{ __('Rma:') }</div>
                    <div className="right">
                        { rmaData.increment_id ? `Nº ${rmaData.rma_id}` : '' }
                    </div>
                </div>
                <div className="rma-info-item order-id">
                    <div className="label">{ __('Order:') }</div>
                    <div className="right">
                        { rmaData.increment_id ? `Nº ${rmaData.increment_id}` : '' }
                    </div>
                </div>
                <div className="rma-info-item rma-date">
                    <div className="label">{ __('Date:') }</div>
                    <div className="right">
                        { date }
                    </div>
                </div>
                <div className="rma-info-item rma-status">
                <div className="label">{ __('Rma Status:') }</div>
                    <div className="right">
                        { rma_status }
                    </div>
                </div>
                <div className="rma-info-item order-status">
                    <div className="label">{ __('Order Status:') }</div>
                    <div className="right">
                        { order_status }
                    </div>
                </div>
                <div className="rma-info-item rma-resolution">
                    <div className="label">{ __('Resolution:') }</div>
                    <div className="right">
                        { rma_resolution }
                    </div>
                </div>
                { rmaData.rma_tracking && rmaData.rma_tracking !== '' ? (
                    <div className="rma-info-item rma-track">
                        <div className="label">
                            { `${__('Track')}:` }
                        </div>
                        <div className="right">
                            { rmaData.rma_tracking }
                        </div>
                    </div>
                ) : null }
                <div className="rma-info-item rma-see-more full">
                    <Link
                      to={ isSignedIn() ? '/customer/rma' : '/customer/account' }
                      rel="noreferrer"
                    >
                        { __('See more') }
                    </Link>
                </div>
            </div>
        );
    }
}

export default ShowRmaInfo;
