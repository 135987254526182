import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ClickOutside from 'Component/ClickOutside';
import OutOfStockForm from 'Component/OutOfStockForm';
import YBCloseIcon from 'Component/YBCloseIcon';
import YBPopupBuilderQuery from 'Query/YBPopupBuilder.query';
import { Popup as SourcePopup } from 'SourceComponent/Popup/Popup.component';
import {
    fetchMutation
} from 'Util/Request';

import './Popup.override.style';

/** @namespace Elganso/Component/Popup/Component */
export class Popup extends SourcePopup {
    hidePopupAndGoBack = this.hidePopupAndGoBack.bind(this);

    hidePopupAndGoBack() {
        const { activeOverlay, idBuilder } = this.props;
        if (activeOverlay === 'popupbuilder' && idBuilder !== undefined) {
            this._createPopupBuilderReportData(idBuilder, 'close');
        }

        this.hidePopUp();
    }

    async _createPopupBuilderReportData(id, type) {
        const mutationPopUpReport = YBPopupBuilderQuery.createPopupReport(id, type);
        await fetchMutation(mutationPopUpReport);
    }

    renderTitle() {
        const { isOutOfStock, title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h3 block="Popup" elem="Heading">
                { !isOutOfStock ? (
                    title
                ) : '' }
            </h3>
        );
    }

    renderCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ () => this.hidePopupAndGoBack() }
            >
                <YBCloseIcon />
            </button>
        );
    }

    handleClickOutside() {
        const { clickOutside, isMobile } = this.props;

        if (!clickOutside || isMobile) {
            return;
        }

        this.hidePopupAndGoBack();
    }

    renderContent() {
        const { children, contentMix, renderTitle } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { renderTitle ? (
                            this.renderTitle()
                        ) : '' }
                        { this.renderCloseButton() }
                    </header>

                    { this.renderNotifications() }
                    { children }
                    { this.renderOutOfStockForm() }
                    { this.renderStripeError() }
                </div>
            </ClickOutside>
        );
    }

    renderOutOfStockForm() {
        const {
            isOutOfStock, product, productClicked, params
        } = this.props;

        if (!isOutOfStock) {
            return null;
        }

        return (
            <OutOfStockForm
              block="OutOfStockForm"
              product={ product }
              productoSeleccionado={ productClicked }
              params={ params }
            />
        );
    }

    renderStripeError() {
        const { stripeError } = this.props;

        if (!stripeError) {
            return null;
        }

        const { icon, message } = stripeError;

        return (
            <div block="Popup" elem="StripeError">
                <FontAwesomeIcon icon={ icon } className="errorIcon" />
                <div>{ message }</div>
                <button
                  block="Popup"
                  elem="CloseStripeError"
                  className="Button"
                  aria-label={ __('Reintentar') }
                  onClick={ () => this.hidePopupAndGoBack() }
                >
                    { __('Reintentar') }
                </button>
            </div>
        );
    }
}

export default Popup;
