/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import axios from 'axios';
import React, { PureComponent } from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';

import getStore from 'Util/Store';

import BotAvatar from './ChatbotComponents/BotAvatar.component';
import CustomBotChatMessage from './ChatbotComponents/CustomBotChatMessage.component';
import GetDisponibilidadTiendaForm from './ChatbotWidgets/GetDisponibilidadTiendaForm.component';
import GetOrderForm from './ChatbotWidgets/GetOrderForm.component';
import GetRmaForm from './ChatbotWidgets/GetRmaForm.component';
import GetSearchForm from './ChatbotWidgets/GetSearchForm.component';
import InitButtons from './ChatbotWidgets/InitButtons.component';
import ShowDisponibilidadTienda from './ChatbotWidgets/ShowDisponibilidadTienda/ShowDisponibilidadTienda.component';
import ShowDownloadApps from './ChatbotWidgets/ShowDownloadApps.component';
import ShowOrderInfo from './ChatbotWidgets/ShowOrderInfo.component';
import ShowRmaInfo from './ChatbotWidgets/ShowRmaInfo.component';
import ShowSocialMedia from './ChatbotWidgets/ShowSocialMedia.component';
import YBChatbot from './YBChatbot.component';

/** @namespace Elganso/Component/YBChatbot/Container */
export class YBChatbotContainer extends PureComponent {
    state = {
        openedChatbot: false,
        showChatbot: false,
        messageHistory: []
    };

    containerFunctions = {
        onClickButtonChat: this.onClickButtonChat.bind(this),
        messageParser: this.messageParser.bind(this),
        actionProvider: this.actionProvider.bind(this)
    };

    componentDidMount() {
        // Comprobamos si el chatbot está activo
        this.checkShowChatbot();
    }

    containerProps() {
        const { openedChatbot, showChatbot } = this.state;
        const saludo = `${__('Welcome to El Ganso.')}<br>${__('How can I help you?')}`;

        const config = {
            botName: 'El Ganso',
            customStyles: {
                chatButton: {
                    backgroundColor: '#00295a'
                }
            },
            initialMessages: [
                // Saludo de bienvenida
                createChatBotMessage(
                    saludo,
                    {
                        widget: 'overview'
                    }
                )
            ],
            customComponents: {
                // Reemplaza el avatar de los mensajes del cahtbot
                botAvatar: () => <BotAvatar />,
                // No mostramos avatar en los mensajes del usuario
                userAvatar: () => null,
                // Modificamos el cuadro de los mensajes del bot para permitir html
                botChatMessage: (props) => <CustomBotChatMessage { ...props } />
            },
            widgets: [
                {
                    widgetName: 'overview',
                    widgetFunc: (props) => <InitButtons { ...props } />
                },
                {
                    widgetName: 'formGetOrder',
                    widgetFunc: (props) => <GetOrderForm { ...props } />
                },
                {
                    widgetName: 'formGetRma',
                    widgetFunc: (props) => <GetRmaForm { ...props } />
                },
                {
                    widgetName: 'showOrder',
                    widgetFunc: (props) => <ShowOrderInfo { ...props } />
                },
                {
                    widgetName: 'showRma',
                    widgetFunc: (props) => <ShowRmaInfo { ...props } />
                },
                {
                    widgetName: 'showSocialMedia',
                    widgetFunc: (props) => <ShowSocialMedia { ...props } />
                },
                {
                    widgetName: 'showDownloadApps',
                    widgetFunc: (props) => <ShowDownloadApps { ...props } />
                },
                {
                    widgetName: 'formGetDisponibilidadTienda',
                    widgetFunc: (props) => <GetDisponibilidadTiendaForm { ...props } />
                },
                {
                    widgetName: 'showDisponibilidadTienda',
                    widgetFunc: (props) => <ShowDisponibilidadTienda { ...props } />
                },
                {
                    widgetName: 'formSearch',
                    widgetFunc: (props) => <GetSearchForm { ...props } />
                }
            ]
        };

        return {
            config,
            openedChatbot,
            showChatbot
        };
    }

    checkShowChatbot() {
        const {
            ConfigReducer: {
                chatbot: {
                    activo = false
                } = {}
            }
        } = getStore().getState();

        this.setState({ showChatbot: activo });
    }

    onClickButtonChat() {
        const { openedChatbot } = this.state;

        this.setState({ openedChatbot: !openedChatbot });

        if (!openedChatbot) {
            document.getElementsByClassName('react-chatbot-kit-chat-input')[0].focus();
        }
    }

    messageParser({ children, actions }) {
        const { messageHistory } = this.state;
        const {
            props: {
                state: {
                    messages = []
                } = {}
            } = {}
        } = children;

        // Si hay mensajes nuevos, los añadimos al historial
        if (messages.length !== 0 && messages.length !== messageHistory.length) {
            const lastMessage = messages[messages.length - 1];

            this.setState({
                messageHistory: [...messageHistory, {
                    role: lastMessage.type === 'bot' ? 'model' : 'user',
                    parts: {
                        text: lastMessage.message
                    }
                }]
            });
        }

        // MANEJADOR DE MENSAJES
        const parse = (message) => {
            // Mandamos el mensaje del usuario a Gemini
            actions.askGemini(message);
        };

        return (
            <div>
                { React.Children.map(children, (child) => React.cloneElement(child, {
                    parse,
                    actions
                })) }
            </div>
        );
    }

    actionProvider({ createChatBotMessage, setState, children }) {
        // Preguntamos a Gemini por la respuesta al mensaje del usuario
        const askGemini = (message) => {
            const { messageHistory } = this.state;
            const {
                ConfigReducer: {
                    code = 'en'
                } = {}
            } = getStore().getState();

            const url = `/rest/${code}/V1/elganso/chatbot`;

            axios.post(url, {
                question: message,
                history: messageHistory
            }).then(
                /** @namespace Elganso/Component/YBChatbot/Container/YBChatbotContainer/actionProvider/askGemini/then/catch/post/then */
                (response) => {
                    if (response.status === 200) {
                        const res = JSON.parse(response.data, true);
                        let botMessage = null;

                        // Si la respuesta contiene un widget, lo mostramos
                        if (res.widget !== undefined) {
                            // Si la respuesta es un pedido, mostramos los datos del pedido
                            if (res.widget === 'showOrder') {
                                botMessage = createChatBotMessage(
                                    res.msg,
                                    {
                                        widget: res.widget,
                                        payload: {
                                            orderData: res.order_data
                                        }
                                    }
                                );
                            // Si la respuesta es una devolución, mostramos los datos de la devolución
                            } else if (res.widget === 'showRma') {
                                botMessage = createChatBotMessage(
                                    res.msg,
                                    {
                                        widget: 'showRma',
                                        payload: {
                                            rmaData: res.rma_data
                                        }
                                    }
                                );
                            // Cualquier otro widget
                            } else {
                                botMessage = createChatBotMessage(res.msg, { widget: res.widget });
                            }
                        } else {
                            // eslint-disable-next-line arrow-body-style
                            botMessage = createChatBotMessage(res.msg,);
                        }

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage]
                        }));
                    }
                }
            ).catch(
                /** @namespace Elganso/Component/YBChatbot/Container/YBChatbotContainer/actionProvider/askGemini/then/catch */
                (error) => {
                    if (error) {
                        this.showChatbotErrorMessage(setState);
                    }
                }
            );
        };

        // Obtenemos los datos de un pedido para mostrarlos en el chat
        const getOrderData = (email, incrementId) => {
            const {
                ConfigReducer: {
                    code = 'en'
                } = {}
            } = getStore().getState();

            const url = `/rest/${code}/V1/elganso/chatbot/getOrderByEmailAndOrderId`;

            axios.post(url, {
                email,
                incrementId
            }).then(
                /** @namespace Elganso/Component/YBChatbot/Container/YBChatbotContainer/actionProvider/getOrderData/then/catch/post/then */
                (response) => {
                    if (response.status === 200) {
                        const res = JSON.parse(response.data, true);

                        if (res.status === 'success') {
                            const botMessage = createChatBotMessage(
                                res.msg,
                                {
                                    widget: 'showOrder',
                                    payload: {
                                        orderData: res.order_data
                                    }
                                }
                            );

                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, botMessage]
                            }));
                        } else {
                            const botMessage = createChatBotMessage(
                                res.msg,
                                {
                                    widget: 'overview'
                                }
                            );

                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, botMessage]
                            }));
                        }
                    }
                }
            ).catch(
                /** @namespace Elganso/Component/YBChatbot/Container/YBChatbotContainer/actionProvider/getOrderData/then/catch */
                (error) => {
                    if (error) {
                        this.showChatbotErrorMessage(setState);
                    }
                }
            );
        };

        // Obtenemos los datos de una devolución para mostrarlos en el chat
        const getRmaData = (email, rmaId) => {
            const {
                ConfigReducer: {
                    code = 'en'
                } = {}
            } = getStore().getState();

            const url = `/rest/${code}/V1/elganso/chatbot/getRmaByEmailAndId`;

            axios.post(url, {
                email,
                rmaId
            }).then(
                /** @namespace Elganso/Component/YBChatbot/Container/YBChatbotContainer/actionProvider/getRmaData/then/catch/post/then */
                (response) => {
                    if (response.status === 200) {
                        const res = JSON.parse(response.data, true);

                        if (res.status === 'success') {
                            const botMessage = createChatBotMessage(
                                res.msg,
                                {
                                    widget: 'showRma',
                                    payload: {
                                        rmaData: res.rma_data
                                    }
                                }
                            );

                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, botMessage]
                            }));
                        } else {
                            const botMessage = createChatBotMessage(
                                res.msg,
                                {
                                    widget: 'overview'
                                }
                            );

                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, botMessage]
                            }));
                        }
                    }
                }
            ).catch(
                /** @namespace Elganso/Component/YBChatbot/Container/YBChatbotContainer/actionProvider/getRmaData/then/catch */
                (error) => {
                    if (error) {
                        this.showChatbotErrorMessage(setState);
                    }
                }
            );
        };

        // Mostramos los enlaces de las redes sociales
        const showSocialMedia = () => {
            const botMessage = createChatBotMessage(__('Here you have all our social networks, with which you can stay up to date with the latest news and consult all the content we publish.'), { widget: 'showSocialMedia' });

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage]
            }));
        };

        // Mostramos el formulario para ver un resumen de pedido
        const formGetOrder = () => {
            const botMessage = createChatBotMessage('Rellena el formulario', { widget: 'formGetOrder' });

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage]
            }));
        };

        // Mostramos el formulario de busqueda de productos
        const formSearch = () => {
            const botMessage = createChatBotMessage(__('Desde aquí podrás encontrar tus articulos más rápido. Busca por prenda, nombre, color o referencia.'), { widget: 'formSearch' });

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage]
            }));
        };

        // Obtenemos los datos de disponibilidad en tienda
        const getDisponibilidadTiendaData = (sku, zipCountry) => {
            const {
                ConfigReducer: {
                    code = 'en'
                } = {}
            } = getStore().getState();

            const url = `/rest/${code}/V1/stores/getstores`;

            axios.post(url, {
                referencia: sku,
                talla: '',
                ciudad_codpostal: zipCountry
            }).then(
                /** @namespace Elganso/Component/YBChatbot/Container/YBChatbotContainer/actionProvider/getDisponibilidadTiendaData/then/catch/post/then */
                (response) => {
                    if (response.status === 200) {
                        const res = JSON.parse(response.data, true);

                        if (res.error) {
                            const botMessage = createChatBotMessage(__('This product is currently not available in store.'));

                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, botMessage]
                            }));
                        } else {
                            const botMessage = createChatBotMessage(
                                __('These are the closest stores where you can find the product:'),
                                {
                                    widget: 'showDisponibilidadTienda',
                                    payload: {
                                        storesData: res
                                    }
                                }
                            );

                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, botMessage]
                            }));
                        }
                    }
                }
            ).catch(
                /** @namespace Elganso/Component/YBChatbot/Container/YBChatbotContainer/actionProvider/getDisponibilidadTiendaData/then/catch */
                (error) => {
                    if (error) {
                        this.showChatbotErrorMessage(setState);
                    }
                }
            );
        };

        return (
            <div>
                { React.Children.map(children, (child) => React.cloneElement(child, {
                    actions: {
                        askGemini,
                        getOrderData,
                        getRmaData,
                        showSocialMedia,
                        getDisponibilidadTiendaData,
                        formGetOrder,
                        formSearch
                    }
                })) }
            </div>
        );
    }

    showChatbotErrorMessage(setState) {
        const botMessage = createChatBotMessage(
            __('At this time I cannot help you, you can contact us through the contact form.'),
            {
                widget: 'overview'
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage]
        }));
    }

    render() {
        return (
            <YBChatbot
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default YBChatbotContainer;
