import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';
import { toggleScroll } from 'Util/Browser';

import './Overlay.override.style';

/** @namespace Elganso/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    onVisible() {
        const { onVisible, isStatic } = this.props;

        if (isStatic) {
            return;
        }

        this.overlayRef.current.focus();
        onVisible();
    }

    onHide() {
        const { onHide, isStatic } = this.props;

        if (isStatic) {
            return;
        }

        onHide();
    }

    freezeScroll() {
        this.YoffsetWhenScrollDisabled = 0;
        toggleScroll(false);
        document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
    }

    unfreezeScroll() {
        toggleScroll(true);
        document.body.style.marginTop = 0;
        window.scrollTo(0, this.YoffsetWhenScrollDisabled);
    }

    getIsVisible(props = this.props) {
        const {
            id, activeOverlay, isStatic, isContentFiltered, isMobile, areOtherOverlaysOpen
        } = props;

        if (id === 'category-filter' && isContentFiltered && !isMobile) {
            if (this.props.activeOverlay === 'category-filter') {
                if (this.props?.customFiltersValues?.category_id && !areOtherOverlaysOpen) {
                    if (Object.keys(this.props.customFiltersValues).length === 1) {
                        return true;
                    }
                }

                return false;
            }
            if (!this.props.activeOverlay && this.props?.customFiltersValues?.category_id) {
                if (Object.keys(this.props.customFiltersValues).length === 1) {
                    return false;
                }
            }

            return true;
        }

        return isStatic || id === activeOverlay;
    }
}

export default OverlayComponent;
