import { PureComponent } from 'react';

import Link from 'Component/Link';
import { isSignedIn } from 'Util/Auth';
import { formatPrice } from 'Util/Price';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/ShowOrderInfo/Component */
export class ShowOrderInfo extends PureComponent {
    formatDate(fecha) {
        let dateArray = fecha.split('-');
        let text = '';

        dateArray = dateArray.reverse();
        text = dateArray.join('/');

        return text;
    }

    formatStatus(status) {
        let fraseStatus = status;

        switch (status) {
        case 'complete':
            fraseStatus = __('Complete');
            break;
        case 'en_camino':
            fraseStatus = __('Enviado');
            break;
        case 'processing':
            fraseStatus = __('Processing');
            break;
        case 'pending':
            fraseStatus = __('Pending');
            break;
        case 'closed':
            fraseStatus = __('Closed');
            break;
        case 'canceled':
            fraseStatus = __('Canceled');
            break;
        default:
            fraseStatus = status;
        }

        return fraseStatus;
    }

    render() {
        const { payload: { orderData } } = this.props;
        const date = this.formatDate(orderData.created_at);
        const status = this.formatStatus(orderData.status);
        let orderTracks = 0;

        return (
            <div className="order-info-container" key={ orderData.increment_id }>
                <div className="order-info-item order-id">
                    <div className="label">{ __('Order:') }</div>
                    <div className="right">
                        { orderData.increment_id ? `Nº ${orderData.increment_id}` : '' }
                    </div>
                </div>
                <div className="order-info-item order-date">
                    <div className="label">{ __('Date:') }</div>
                    <div className="right">
                        { date }
                    </div>
                </div>
                <div className="order-info-item order-items">
                    <div className="label">{ __('Items:') }</div>
                    <div className="right">
                        <span className="numero-items">
                            { orderData.total_qty_ordered } { orderData.total_qty_ordered === 1 ? __('item') : __('items') }
                        </span>
                    </div>
                </div>
                <div className="order-info-item order-status">
                    <div className="label">{ __('Status:') }</div>
                    <div className="right">
                        { status }
                    </div>
                </div>
                <div className="order-info-item order-price">
                    <div className="label">{ __('Price:') }</div>
                    <div className="right">
                        { orderData.grand_total ? formatPrice(orderData.grand_total, orderData.currency) : '' }
                    </div>
                </div>
                <div className="order-info-item order-see-more full">
                    { orderData.order_url !== undefined && orderData.order_url !== null ? (
                        <Link
                          to={ isSignedIn() ? orderData.order_url : '/customer/account' }
                          rel="noreferrer"
                        >
                            { __('See more') }
                        </Link>
                    ) : null }
                </div>
                { orderData.tracks !== undefined && orderData.tracks !== null ? (
                    <div className={ `order-info-item order-track ${orderData.tracks.length > 1 ? 'multi' : 'single'}` }>
                        <div className="track-title">
                            { __('Track') }
                        </div>

                        { orderData.tracks.map((track) => {
                            orderTracks++;

                            return (
                                <div className="track-item">
                                    <div className="ShipmentInfo">
                                        { track.track_url ? (
                                            <Link
                                              to={ track.track_url }
                                              rel="noreferrer"
                                              isOpenInNewTab
                                              className="track-url"
                                            >
                                                { __('Shipment') } { `${orderTracks} - ${track.carrier_code} ( ${track.track_number} )` }
                                            </Link>
                                        ) : (
                                            <div className="track-url">
                                                { __('Shipment') } { `${orderTracks} - ${track.carrier_code} ( ${track.track_number} )` }
                                            </div>
                                        ) }
                                    </div>
                                    <div className="ShipmentItems">
                                        <span className="itemTitle">{ __('Items') }:</span>
                                        { track.items.map((item) => (
                                            <div className="shipmentItem">
                                                <div>{ item.name }</div>
                                                <div>{ item.sku }</div>
                                                <div>{ __('Quantity: %s', parseInt(item.qty, 2)) }</div>
                                            </div>
                                        )) }
                                    </div>
                                    { track.history_comments ? (
                                        <div className="ShipmentHistory">
                                            <span className="itemTitle">{ __('Record') }:</span>
                                            { track.history_comments.map((item) => (
                                                <div className="historyItem">
                                                    <div><strong>{ item.date }</strong> | { item.status }</div>
                                                    <div>{ item.comment }</div>
                                                </div>
                                            )) }
                                        </div>
                                    ) : null }
                                </div>
                            );
                        }) }
                    </div>
                ) : null }
            </div>
        );
    }
}

export default ShowOrderInfo;
