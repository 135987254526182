import { PureComponent } from 'react';

import SubscriptionQuery from 'Query/Subscription.query';
import { getVariantIndex } from 'Util/Product';
import { fetchMutation } from 'Util/Request';

import OutOfStockForm from './OutOfStockForm.component';

/** @namespace Elganso/Component/OutOfStockForm/Container */
export class OutOfStockFormContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        onAcceptTerm: this.onAcceptTerm.bind(this),
        submitSubscription: this.submitSubscription.bind(this),
        _getSelectOptions: this._getSelectOptions.bind(this),
        onConfigurableSelected: this.onConfigurableSelected.bind(this)
    };

    state = {
        termsAreEnabled: false,
        respuestaSuscripcion: null,
        parameters: {},
        isFormLoading: false
    };

    containerProps() {
        const {
            product,
            productoSeleccionado,
            params
        } = this.props;

        const { isFormLoading, parameters } = this.state;

        const {
            termsAreEnabled,
            respuestaSuscripcion
        } = this.state;

        return {
            product,
            parameters,
            termsAreEnabled,
            respuestaSuscripcion,
            productoSeleccionado,
            params,
            isFormLoading
        };
    }

    onConfigurableSelected(value) {
        const { parameters } = this.state;
        parameters.size = value;
        this.setState({ parameters });
    }

    getIsConfigurableAttributeAvailable({ _attribute_code, attribute_value }) {
        const { params, product: { variants } } = this.props;
        if (params && 'color' in params) {
            const newParameter = { ...params };
            newParameter.size = attribute_value;
            const variantIndex = getVariantIndex(variants, newParameter);
            if (variantIndex === -1) {
                return false;
            }
        }

        return true;
    }

    _getSelectOptions(option) {
        const {
            attribute_options,
            attribute_code
        } = option;

        if (!attribute_options) {
            // eslint-disable-next-line no-console
            console.warn(`Please make sure "${ attribute_code }" is visible on Storefront.`);

            return [];
        }

        return Object.values(attribute_options)
            .reduce((acc, option) => {
                const { value } = option;

                const isAvailable = this.getIsConfigurableAttributeAvailable({
                    attribute_code,
                    attribute_value: value
                });

                return [...acc, {
                    ...option,
                    id: value,
                    isAvailable
                }];
            }, []);
    }

    getProductVariacion(product, parameters = null) {
        if (!parameters) {
            return product;
        }

        const newIndex = getVariantIndex(product.variants, parameters);
        return newIndex === -1 ? product : product.variants[newIndex];
    }

    onAcceptTerm(field) {
        if (field.target.checked) {
            this.setState({ termsAreEnabled: true });
        } else {
            this.setState({ termsAreEnabled: false });
        }
    }

    submitSubscription(form, fields) {
        this.setState({ isFormLoading: true });

        const { product, productoSeleccionado, params } = this.props;
        const { parameters } = this.state;
        const newParameter = { ...parameters };
        if (params && 'color' in params) {
            newParameter.color = params.color;
        }
        const producto_suscrito = productoSeleccionado || this.getProductVariacion(product, newParameter);
        const localStorageConfig = JSON.parse(window.localStorage.config);
        const storeCode = localStorageConfig.data.storeConfig.code;

        const suscripcion = {
            email: fields.find((obj) => obj.name === 'subscription_email').value,
            sku: producto_suscrito.sku,
            nombre: producto_suscrito.name,
            estado: 'Registrado',
            product_id: producto_suscrito.id,
            store_id: this.getStoreIdByCode(storeCode)
        };

        this.createSuscription(suscripcion);
    }

    async createSuscription(suscription) {
        const query = SubscriptionQuery.getCreateSubscriptionMutation(suscription);

        try {
            const result = await fetchMutation(query);
            this.setState({ respuestaSuscripcion: result, isFormLoading: false });
        } catch (e) {
            this.setState({ respuestaSuscripcion: e, isFormLoading: false });
        }
    }

    getStoreIdByCode(storeCode) {
        switch (storeCode) {
        case 'es':
            return 1;
        case 'en':
            return 2;
        case 'fr':
            return 3;
        case 'intl_es':
            return 4;
        case 'intl_en':
            return 5;
        case 'intl_fr':
            return 6;
        case 'intl_uk':
            return 7;
        case 'intl_de':
            return 8;
        case 'fr_fr':
            return 9;
        case 'pt_es':
            return 10;
        case 'pt_pt':
            return 11;
        case 'es_cn':
            return 12;
        case 'intl_cl':
            return 13;
        case 'intl_mx':
            return 14;
        default:
            return 1;
        }
    }

    render() {
        return (
            <OutOfStockForm
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default OutOfStockFormContainer;
