import {
    getInitialState as SourceGetInitialState
} from 'SourceStore/Notification/Notification.reducer';
import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from 'Store/Notification/Notification.action';

/** @namespace Elganso/Store/Notification/Reducer/getInitialState */
export const getInitialState = SourceGetInitialState;

/** @namespace Elganso/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (
    state = getInitialState(),
    action
) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
    case SHOW_NOTIFICATION:
        const { msgType, msgText, msgDebug } = action;

        notifications[Date.now()] = { msgType, msgText, msgDebug };

        return {
            ...state,
            notifications
        };

    case HIDE_NOTIFICATION:
        const { [action.id]: id, ...shownNotifications } = notifications;
        // YEBO Si no hay id eliminamos notificaciones para evitar que salgan todas de golpe
        if (!id) {
            return {
                ...state,
                notifications: {}
            };
        }

        return {
            ...state,
            notifications: shownNotifications
        };

    default:
        return state;
    }
};

export default NotificationReducer;
