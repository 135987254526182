import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/** @namespace Elganso/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    getQuery(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        // COMPROBAR SI ES CARGA SECUNDARIA, TRAER GALERIA Y OTROS
        if (options.onlyMedia !== undefined && options.onlyMedia) {
            return this._getOnlyMediaProductsField();
        }

        return this._getProductsField();
    }

    _getOnlyMediaProductsField() {
        const products = new Field('products')
            .addFieldList([new Field('items').addFieldList(this._getOnlyMediaProductInterfaceFields())]);

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        return products;
    }

    _getOnlyMediaProductInterfaceFields(isVariant = false) {
        const fields = ['uid', 'id', 'name', 'type_id'];

        fields.push(
            this._getMediaGalleryField()
        );

        if (!isVariant) {
            fields.push(new Fragment('ConfigurableProduct').addFieldList([
                new Field('variants')
                    .addFieldList([
                        new Field('product').addFieldList(this._getOnlyMediaProductInterfaceFields(true))
                    ])
            ]));
        }

        return fields;
    }

    _getBundleOptionsFields() {
        return [
            'uid',
            'label',
            'quantity',
            'position',
            'is_default',
            'price',
            'price_type',
            'can_change_quantity',
            this._getProductBundleOptionField(),
            this._getProductBundleAttributeptionField()
        ];
    }

    _getProductBundleOptionFields() {
        return [
            'name',
            'stock_status',
            'sku',
            this._getPriceRangeField()
        ];
    }

    _getProductBundleAttributeptionField() {
        return new Field('attribute_options')
            .addFieldList(this._getProductBundleAttributeptionFields());
    }

    _getProductBundleAttributeptionFields() {
        return [
            'color',
            'colorSwatch',
            'size',
            'smallImage'
        ];
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            basicFields = false
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        if (basicFields) {
            return ['uid', 'id', 'name', 'url', 'type_id'];
        }

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'salable_qty',
            'disponibilidad_tiendas',
            'preorder',
            'yeboyebo_carrito_promociones_fecha_hasta',
            'yeboyebo_carrito_promociones_precio',
            'producto_asociado',
            'gruporemarketing',
            // this._getStockItemField(),
            this._getPriceRangeField(),
            this._getProductLinksField(),
            this._getCategoriesField()
        ];

        // eslint-disable-next-line no-constant-condition
        if (this.options.returnAllData === true || true) {
            fields.push(
                // this._getCategoriesField(),
                this._getMediaGalleryField()
            );
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductImageField(),
                // this._getProductThumbnailField(),
                // this._getProductSmallField(),
                // this._getShortDescriptionField(),
                // 'special_from_date',
                // 'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getDescriptionField(),
                this._getCustomizableProductFragment()
                // this._getReviewCountField(),
                // this._getRatingSummaryField(),
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        } else {
            fields.push(
                'canonical_url',
                'id',
                'url_key'
            );
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    // this._getReviewsField(),
                    // this._getVirtualProductFragment(),
                    // this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    _getCartProductInterfaceFields(isCart = false) {
        if (!isCart) {
            return [
                'uid',
                'id',
                'sku',
                'name',
                'type_id',
                'stock_status',
                'url',
                'salable_qty',
                // this._getStockItemField(),
                // this._getProductThumbnailField(),
                this._getCartConfigurableProductFragment(),
                this._getAttributesField(false, true),
                this._getProductLinksField()
            ];
        }

        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            // 'stock_status',
            'url',
            'preorder',
            'yeboyebo_carrito_promociones_fecha_hasta',
            'yeboyebo_carrito_promociones_precio',
            'producto_asociado',
            // 'salable_qty',
            // this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this._getProductImageField()
        ];
    }

    _getCartProductField() {
        return new Field('product')
            .addFieldList([
                'id',
                'sku',
                'stock_status',
                'salable_qty',
                'preorder',
                'yeboyebo_carrito_promociones_fecha_hasta',
                'yeboyebo_carrito_promociones_precio',
                'producto_asociado',
                this._getStockItemField(),
                this._getProductThumbnailField(),
                this._getAttributesField(true, true)
            ]);
    }

    _getCustomFilters(filters = {}) {
        return Object.entries(filters)
            .reduce((acc, [key, attribute]) => {
                if (!attribute.length) {
                    return acc;
                }

                if (key === 'price') {
                    return {
                        ...acc,
                        ...this._getPriceFilter(key, attribute)
                    };
                }

                if (key === 'category_id' && attribute.length === 1) {
                    return {
                        ...acc,
                        [key]: { eq: attribute[0] }
                    };
                }

                return {
                    ...acc,
                    [key]: { in: attribute }
                };
            }, {});
    }
}

export default new ProductListQuery();
