import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { getUrlMedia } from 'Util/Store';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/ShowDownloadApps/Component */
export class ShowDownloadApps extends PureComponent {
    render() {
        return (
            <div className="download-apps-container">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.elganso.store&hl=es&gl=US"
                  rel="noreferrer"
                  isOpenInNewTab
                >
                    <Image src={ `${getUrlMedia()}scandiweb/images/download-google-play.png` } alt="Android El Ganso" />
                </Link>
                <Link
                  to="https://apps.apple.com/es/app/el-ganso/id1173967862"
                  rel="noreferrer"
                  isOpenInNewTab
                >
                    <Image src={ `${getUrlMedia()}scandiweb/images/download-app-store.png` } alt="iOS El Ganso" />
                </Link>
            </div>
        );
    }
}

export default ShowDownloadApps;
