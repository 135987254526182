import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { validateEmail } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/GetOrderForm/Component */
export class GetOrderForm extends PureComponent {
    state = {
        email: '',
        order: null
    };

    handleEmailInput = this.handleEmailInput.bind(this);

    handleOrderInput = this.handleOrderInput.bind(this);

    handleGetOrder = this.handleGetOrder.bind(this);

    handleEmailInput(e, field) {
        const { value: email } = field;

        this.setState({ email });
    }

    handleOrderInput(e, field) {
        const { value: order } = field;

        this.setState({ order });
    }

    handleGetOrder(e) {
        const { actionProvider } = this.props;
        const { email, order } = this.state;
        e.preventDefault();

        actionProvider.getOrderData(email, order);
    }

    render() {
        const { email, order } = this.state;

        return (
            <div className="request-order-form">
                <div block="FieldForm" elem="Body">
                    <div block="FieldForm" elem="Fields">
                        <Field
                          key="email"
                          type={ FIELD_TYPE.email }
                          attr={ {
                              id: 'email',
                              name: 'email',
                              defaultValue: email,
                              placeholder: `${__('Email') } *`,
                              autocomplete: 'email'
                          } }
                          validateOn={ ['onChange'] }
                          events={ { onChange: this.handleEmailInput } }
                          validationRule={ {
                              isRequired: true,
                              inputType: VALIDATION_INPUT_TYPE.email,
                              match: (value) => validateEmail(value)
                          } }
                          addRequiredTag
                        />
                        <Field
                          key="order"
                          type={ FIELD_TYPE.number }
                          attr={ {
                              id: 'order',
                              name: 'order',
                              defaultValue: order,
                              placeholder: `${__('Order') } *`
                          } }
                          validateOn={ ['onChange'] }
                          events={ { onChange: this.handleOrderInput } }
                          validationRule={ {
                              inputType: VALIDATION_INPUT_TYPE.numeric,
                              isRequired: true
                          } }
                          addRequiredTag
                        />
                        <button
                          block="Button"
                          type="submit"
                          className="getOrderButton"
                          disabled={ !(email !== '' && order !== null) }
                          onClick={ this.handleGetOrder }
                        >
                            { __('Submit') }
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetOrderForm;
