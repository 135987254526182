import Html from 'Component/Html';
import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

import './Notification.override.style';

/** @namespace Elganso/Component/Notification/Component */
export class Notification extends SourceNotification {
    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        const message = msgText.toString() || msgText.value || msgText;

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }> Close </button>
                <p block="Notification" elem="Text">
                    <Html content={ message } />
                </p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default Notification;
