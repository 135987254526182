import { PureComponent } from 'react';

import Image from 'Component/Image';
import { getUrlMedia } from 'Util/Store';

/** @namespace Elganso/Component/YBChatbot/ChatbotComponents/BotAvatar/Component */
export class BotAvatar extends PureComponent {
    render() {
        return (
            <div className="react-chatbot-kit-chat-bot-avatar">
                <div
                  className="react-chatbot-kit-chat-bot-avatar-container"
                  style={ { background: 'none' } }
                >
                <Image
                  alt="BotAvatar"
                  src={ `${getUrlMedia()}scandiweb/images/map_marker.png` }
                />
                </div>
            </div>
        );
    }
}

export default BotAvatar;
