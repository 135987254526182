import {
    configureStore as SourceConfigureStore,
    getStore as SourceGetStore,
    noopReducer as SourceNoopReducer
} from 'SourceUtil/Store/index';

export const configureStore = SourceConfigureStore;

export const noopReducer = SourceNoopReducer;

export const getStore = SourceGetStore;

/*
 * Obtenemos toda la configuracion de las redes sociales que hay configuradas en el admin y se guardan en el localStorage
 * @namespace Elganso/Util/Store/Index/getSocialMedia */
export const getSocialMedia = () => {
    const {
        ConfigReducer: {
            socialMediaConfig = {}
        }
    } = getStore().getState();

    if ('instagram' in socialMediaConfig) {
        return socialMediaConfig;
    }

    return null;
};

/**
 * Obtener Url Media (en produccion vendra cdn)
 * @namespace Elganso/Util/Store/Index/getUrlMedia
 */
export const getUrlMedia = () => {
    const {
        ConfigReducer: {
            secure_base_media_url = 'https://cdn.elganso.com/media/'
        } = {}
    } = getStore().getState();

    return secure_base_media_url;
};

export default getStore;
