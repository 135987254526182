// import PropTypes from 'prop-types';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PureComponent } from 'react';

import './YBCloseIcon.style';

/** @namespace Elganso/Component/YBCloseIcon/Component */
export class YBCloseIconComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <FontAwesomeIcon icon={ faXmark } />
        );
    }
}

export default YBCloseIconComponent;
