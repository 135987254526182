/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
*/

import FIELD_TYPE from 'Component/Field/Field.config';
import { validateEmail } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/**
  * Returns fields for outofstock form
  * @returns {Object}
  * @namespace Elganso/Component/OutOfStockForm/Form/OutOfStockFormulario  */
export const OutOfStockFormulario = (props, _events = {}) => [
    {
        type: FIELD_TYPE.email,
        label: __('introduce tu email aquí'),
        attr: {
            name: 'subscription_email',
            'aria-label': __('Email'),
            className: 'outofstockform_email'
        },
        validateOn: ['onChange'],
        validationRule: {
            inputType: VALIDATION_INPUT_TYPE.email,
            isRequired: true,
            match: (value) => validateEmail(value)
        },
        addRequiredTag: true
    }
];

export default OutOfStockFormulario;
