import { PureComponent } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { getSocialMedia, getUrlMedia } from 'Util/Store';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/ShowSocialMedia/Component */
export class ShowSocialMedia extends PureComponent {
    render() {
        const socialMedia = getSocialMedia();

        return (
            <div className="social-media-container">
                <Swiper
                  navigation={ false }
                  modules={ [Navigation] }
                  spaceBetween={ 0 }
                  slidesPerView="auto"
                  centerInsufficientSlides
                  initialSlide={ 0 }
                >
                    <SwiperSlide key="instagram">
                        <Link
                          to={ `https://instagram.com/${socialMedia?.instagram ? socialMedia.instagram : ''}` }
                          rel="noreferrer"
                          isOpenInNewTab
                        >
                            <Image src={ `${getUrlMedia()}scandiweb/images/iconos_rrss/Iconos-06.png` } alt="Instagram El Ganso" />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide key="tiktok">
                        <Link
                          to="https://www.tiktok.com/@elgansofficial?lang=es&sender_device=pc&sender_web_id=6883044811568498181&is_from_webapp=1"
                          rel="noreferrer"
                          isOpenInNewTab
                        >
                            <Image src={ `${getUrlMedia()}scandiweb/images/iconos_rrss/Iconos-07.png` } alt="TikTok El Ganso" />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide key="twitter">
                        <Link
                          to={ `https://twitter.com/${socialMedia?.twitter ? socialMedia.twitter : ''}` }
                          rel="noreferrer"
                          isOpenInNewTab
                        >
                            <Image src={ `${getUrlMedia()}scandiweb/images/iconos_rrss/Iconos-02.png` } alt="Twitter El Ganso" />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide key="youtube">
                        <Link
                          to={ `https://www.youtube.com/user/${socialMedia?.youtube ? socialMedia.youtube : ''}` }
                          rel="noreferrer"
                          isOpenInNewTab
                        >
                            <Image src={ `${getUrlMedia()}scandiweb/images/iconos_rrss/Iconos-04.png` } alt="Youtube El Ganso" />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide key="facebook">
                        <Link
                          to={ `https://www.facebook.com/${socialMedia?.facebook ? socialMedia.facebook : ''}` }
                          rel="noreferrer"
                          isOpenInNewTab
                        >
                            <Image src={ `${getUrlMedia()}scandiweb/images/iconos_rrss/Iconos-01.png` } alt="Facebook El Ganso" />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide key="linkedin">
                        <Link
                          to={ `https://www.linkedin.com/${socialMedia?.linkedin ? socialMedia.linkedin : ''}` }
                          rel="noreferrer"
                          isOpenInNewTab
                        >
                            <Image src={ `${getUrlMedia()}scandiweb/images/iconos_rrss/Iconos-03.png` } alt="Linkedin El Ganso" />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide key="pinterest">
                        <Link
                          to={ `https://www.pinterest.com/${socialMedia?.pinterest ? socialMedia.pinterest : ''}` }
                          rel="noreferrer"
                          isOpenInNewTab
                        >
                            <Image src={ `${getUrlMedia()}scandiweb/images/iconos_rrss/Iconos-05.png` } alt="Pinterest El Ganso" />
                        </Link>
                    </SwiperSlide>
                </Swiper>
            </div>
        );
    }
}

export default ShowSocialMedia;
