/* eslint-disable react/style-prop-object */
import { PureComponent } from 'react';

/** @namespace Elganso/Component/YBChatbot/ChatbotComponents/CustomBotChatMessage/Component */
export class CustomBotChatMessage extends PureComponent {
    render() {
        const { message } = this.props;

        const chatBoxCustomStyles = { backgroundColor: '#00295a' };
        const arrowCustomStyles = { borderRightColor: '#00295a' };

        return (
            <div
              className="react-chatbot-kit-chat-bot-message"
              style={ chatBoxCustomStyles }
            >
                { /* eslint-disable-next-line react/no-danger */ }
                <div dangerouslySetInnerHTML={ { __html: message } } />
                <div
                  className="react-chatbot-kit-chat-bot-message-arrow"
                  style={ arrowCustomStyles }
                />
            </div>
        );
    }
}

export default CustomBotChatMessage;
