import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

/** @namespace Elganso/Component/YBChatbot/ChatbotWidgets/GetDisponibilidadTiendaForm/Component */
export class GetDisponibilidadTiendaForm extends PureComponent {
    state = {
        sku: '',
        zipCountry: ''
    };

    handleZipCountryInput = this.handleZipCountryInput.bind(this);

    handleSkuInput = this.handleSkuInput.bind(this);

    handleGetDisponibilidad = this.handleGetDisponibilidad.bind(this);

    componentDidMount() {
        const activeSlide = document.getElementsByClassName('swiper-slide-active')[0];

        const item = activeSlide.getElementsByClassName('ProductPageSku')[0];

        if (item !== undefined && item !== null) {
            const { children } = item;

            for (let i = 0; i < children.length; i++) {
                const child = children[i];

                if (child.hasAttribute('itemprop') && child.getAttribute('itemprop') === 'sku') {
                    this.setState({ sku: child.textContent });
                }
            }
        }
    }

    handleSkuInput(e, field) {
        const { value: sku = '' } = field;

        this.setState({ sku });
    }

    handleZipCountryInput(e, field) {
        const { value: zipCountry = '' } = field;

        this.setState({ zipCountry });
    }

    handleGetDisponibilidad(e) {
        const { actionProvider } = this.props;
        const { sku, zipCountry } = this.state;
        e.preventDefault();

        actionProvider.getDisponibilidadTiendaData(sku, zipCountry);
    }

    render() {
        const { sku, zipCountry } = this.state;

        return (
            <div className="request-disponibilidad-form">
                <div block="FieldForm" elem="Body">
                    <div block="FieldForm" elem="Fields">
                        <Field
                          key="sku"
                          type={ FIELD_TYPE.text }
                          attr={ {
                              id: 'sku',
                              name: 'sku',
                              defaultValue: sku,
                              placeholder: 'Sku *'
                          } }
                          validateOn={ ['onChange'] }
                          events={ { onChange: this.handleSkuInput } }
                          validationRule={ {
                              isRequired: true
                          } }
                          addRequiredTag
                        />
                        <Field
                          key="zipCountry"
                          type={ FIELD_TYPE.text }
                          attr={ {
                              id: 'zipCountry',
                              name: 'zipCountry',
                              defaultValue: zipCountry,
                              placeholder: `${__('Zip/Postal Code or City') } *`
                          } }
                          validateOn={ ['onChange'] }
                          events={ { onChange: this.handleZipCountryInput } }
                          validationRule={ {
                              isRequired: true
                          } }
                          addRequiredTag
                        />
                        <button
                          block="Button"
                          type="submit"
                          className="getDisponibilidadButton"
                          disabled={ !(sku !== '' && zipCountry !== null) }
                          onClick={ this.handleGetDisponibilidad }
                        >
                            { __('Submit') }
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetDisponibilidadTiendaForm;
