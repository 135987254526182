/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Subscription Mutations
 * @class Subscription
 * @namespace Elganso/Query/Subscription/Query */
export class SubscriptionQuery {
    getSubscriptionQuery() {
        return new Field('getAllSubscriptions')
            .addFieldList(this._getSubscriptionFields());
    }

    _getSubscriptionFields() {
        return [
            'subscription_id',
            'email',
            'sku',
            'nombre',
            'estado',
            'product_id',
            'store_id'
        ];
    }

    getCreateSubscriptionMutation(options) {
        return new Field('addSubscription')
            .addArgument('input', 'SusbcriptionInput!', options)
            .addFieldList(this._getNewSubscriptionFields());
    }

    _getNewSubscriptionFields() {
        return [
            'result',
            'message'
        ];
    }
}

export default new SubscriptionQuery();
